<template>
    <div>
        <b-row class="mt-n2">
            <!-- card actions -->
            <b-col cols="9">
                <b-card class="font-weight-bolder text-dark">
                    <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Lama Penyinaran (Jam)</h3>
                    <b-row>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1 bg-light-primary"
                                    >6-7 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 6 - 7 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_6_7"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_6_7)"
                                v-model.number="$v.ss_6_7.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_6_7')" :triggers="'hover'" target="ss_6_7" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_6_7") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1 bg-light-primary"
                                    >7-8 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 7 - 8 dalam satuan jam "
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_7_8"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_7_8)"
                                v-model.number="$v.ss_7_8.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_7_8')" :triggers="'hover'" target="ss_7_8" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_7_8") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >8-9 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 8 - 9 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_8_9"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_8_9)"
                                v-model.number="$v.ss_8_9.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_8_9')" :triggers="'hover'" target="ss_8_9" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_8_9") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >9-10 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 9 - 10 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_9_10"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_9_10)"
                                v-model.number="$v.ss_9_10.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_9_10')" :triggers="'hover'" target="ss_9_10" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_9_10") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >10-11 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 10 - 11 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_10_11"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_10_11)"
                                v-model.number="$v.ss_10_11.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_10_11')" :triggers="'hover'" target="ss_10_11" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_10_11") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >11-12 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 11 - 12 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_11_12"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_11_12)"
                                v-model.number="$v.ss_11_12.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_11_12')" :triggers="'hover'" target="ss_11_12" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_11_12") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >12-13 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 12 - 13 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_12_13"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_12_13)"
                                v-model.number="$v.ss_12_13.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_12_13')" :triggers="'hover'" target="ss_12_13" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_12_13") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >13-14 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 13 - 14 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_13_14"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_13_14)"
                                v-model.number="$v.ss_13_14.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_13_14')" :triggers="'hover'" target="ss_13_14" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_13_14") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >14-15 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 14 - 15 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_14_15"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_14_15)"
                                v-model.number="$v.ss_14_15.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_14_15')" :triggers="'hover'" target="ss_14_15" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_14_15") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >15-16 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 15 - 16 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_15_16"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_15_16)"
                                v-model.number="$v.ss_15_16.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_15_16')" :triggers="'hover'" target="ss_15_16" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_15_16") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1 bg-light-primary"
                                    >16-17 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 16 - 17 dalam satuan jam"
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_16_17"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_16_17)"
                                v-model.number="$v.ss_16_17.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_16_17')" :triggers="'hover'" target="ss_16_17" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_16_17") }}
                            </b-tooltip>
                        </b-col>
                        <b-col sm="1 pad2">
                            <div>
                                <label for="input60" class="label-entry1 bg-light-primary"
                                    >17-18 <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" v-b-tooltip.hover.v-dark title="Jumlah pembakaran pada pukul 17 - 18 dalam satuan jam "
                                /></label>
                            </div>
                            <b-form-input
                                v-on:keydown="onKeyCheck($event)"
                                id="ss_17_18"
                                :formatter="format_one_decimal"
                                type="number"
                                :state="validateState($v.ss_17_18)"
                                v-model.number="$v.ss_17_18.$model"
                                size="sm"
                                class="input-entry mb-2 text-center"
                                placeholder=""
                            ></b-form-input>
                            <b-tooltip :show="validateTooltipState('ss_17_18')" :triggers="'hover'" target="ss_17_18" placement="top" custom-class="validation-tooltip">
                                {{ tooltipMessage("ss_17_18") }}
                            </b-tooltip>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>

            <b-col cols="3">
                <b-card class="font-weight-bolder text-dark">
                    <h3 class="mb-2 font-weight-bolder badge badge-light-dark">Jumlah Lama Penyinaran</h3>
                    <b-row class="reduce-margin-row">
                        <b-col sm="6 pad1">
                            <div>
                                <label for="input60" class="label-entry1"
                                    >8 Jam
                                    <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips1" />
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips1" placement="top">
                                        <dl class="text-left">
                                            <dt>8 jam (kolom kiri)</dt>
                                            <dd>- Jumlah pembakaran pada pukul 8 - 16 dalam satuan jam</dd>
                                            <dt>8 jam (kolom kanan)</dt>
                                            <dd>- Persentase pembakaran pada pukul 8 - 16 dalam satuan persen (jumlah pembakaran 8 jam dibagi 8 x 100)</dd>
                                        </dl>
                                    </b-tooltip></label
                                >
                            </div>
                        </b-col>
                        <b-col sm="6 pad1">
                            <div>
                                <label for="input60" class="label-entry1 bg-light-primary"
                                    >12 Jam <feather-icon icon="HelpCircleIcon" size="12" class="bg-primary rounded-circle text-white" id="tooltips2" />
                                    <!-- HTML tooltips -->
                                    <b-tooltip target="tooltips2" placement="top">
                                        <dl class="text-left">
                                            <dt>12 jam (kolom kiri)</dt>
                                            <dd>- Jumlah pembakaran pada pukul 6 - 18 dalam satuan jam</dd>
                                            <dt>12 jam (kolom kanan)</dt>
                                            <dd>- Persentase pembakaran pada pukul 6 - 18 dalam satuan persen (jumlah pembakaran 12 jam dibagi 12 x 100)</dd>
                                        </dl>
                                    </b-tooltip></label
                                >
                            </div>
                        </b-col>

                        <b-col sm="3 mb-2 pad2">
                            <b-form-input id="input10" readonly type="number" v-model.number="ss_total_8" size="sm" class="text-center bg-light-success" placeholder=""></b-form-input>
                        </b-col>
                        <b-col sm="3 mb-2 pad2">
                            <b-form-input id="input10" readonly size="sm" v-model="percen_8h" class="text-center bg-light-success" placeholder="%"></b-form-input>
                        </b-col>
                        <b-col sm="3 mb-2 pad2">
                            <b-form-input id="input10" readonly type="number" v-model.number="ss_total_12" size="sm" class="text-center bg-light-success" placeholder=""></b-form-input>
                        </b-col>
                        <b-col sm="3 mb-2 pad2">
                            <b-form-input id="input10" readonly size="sm" v-model="percen_12h" class="text-center bg-light-success" placeholder="%"></b-form-input>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>

        <b-row class="mt-n2">
            <b-col cols="12">
                <b-card>
                    <b-row class="float-right">
                        <div>
                            <b-button variant="danger" @click="resetForm" class="mr-2" size="sm"><b-icon icon="x-circle-fill"></b-icon> Cancel</b-button>
                            <b-button variant="warning" @click="V_Form" v-if="this.t_data_status == 'update'" class="mr-2" size="sm" :disabled="!roles.isRoleEdit"><b-icon icon="pencil-fill"></b-icon> Update</b-button>
                            <b-button variant="primary" @click="V_Form" v-if="this.t_data_status == 'insert'" class="mr-2" size="sm" :disabled="!roles.isRoleAdd"><b-icon icon="cursor-fill"></b-icon> Submit</b-button>
                        </div>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import BCardActions from "@core/components/b-card-actions/BCardActions.vue";
import { BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, VBTooltip, BTooltip } from "bootstrap-vue";
import axios from "axios";
import Helper from "@/api/helper";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import error_handler from "@/api/error_handler";
import { validationMixin } from "vuelidate";
import { required, minLength, between } from "vuelidate/lib/validators";
import { V_LamaPenyinaran, GC_PIAS } from "@/validators/piasvalidator";
import Ripple from "vue-ripple-directive";

export default {
    components: {
        BCardActions,
        BRow,
        BCol,
        BTable,
        BCardText,
        BFormInput,
        BContainer,
        BButton,
        BCard,
        VBTooltip,
        BTooltip,
    },
    props: ["t_station", "t_wmoid", "t_observer", "t_observer_name", "t_date", "t_station_id", "t_path", "t_data", "t_id_form", "t_data_status", "t_path_update", "roles", "count_form_click"],
    mixins: [validationMixin],
    validations: {
        ss_6_7: { required, V_LamaPenyinaran },
        ss_6_7: { required, V_LamaPenyinaran },
        ss_7_8: { required, V_LamaPenyinaran },
        ss_8_9: { required, V_LamaPenyinaran },
        ss_9_10: { required, V_LamaPenyinaran },
        ss_10_11: { required, V_LamaPenyinaran },
        ss_11_12: { required, V_LamaPenyinaran },
        ss_12_13: { required, V_LamaPenyinaran },
        ss_13_14: { required, V_LamaPenyinaran },
        ss_14_15: { required, V_LamaPenyinaran },
        ss_15_16: { required, V_LamaPenyinaran },
        ss_16_17: { required, V_LamaPenyinaran },
        ss_17_18: { required, V_LamaPenyinaran },
    },
    data() {
        return {
            ss_6_7: "",
            ss_7_8: "",
            ss_8_9: "",
            ss_9_10: "",
            ss_10_11: "",
            ss_11_12: "",
            ss_12_13: "",
            ss_13_14: "",
            ss_14_15: "",
            ss_15_16: "",
            ss_16_17: "",
            ss_17_18: "",
            lon: "",
            lat: "",
            dpl: "",
        };
    },
    mounted() {
        if (this.t_data_status == "update") {
            this.popupData();
        } else {
            this.resetForm();
        }
    },
    watch: {
        t_station(value) {
            console.log("ubah station : ", this.t_path);
        },
        t_data_status(newValue, OldValue) {
            if (newValue == "update") {
                this.popupData();
            } else {
                this.resetForm();
            }
        },
        count_form_click(NewValue, OldValue) {
            if (this.t_data_status == "insert") {
                if (NewValue != OldValue) {
                    this.resetForm();
                }
            }
        },
    },
    methods: {
        onKeyCheck(event) {
            console.log("onKeyCheck", event);
            if (event.key === "ArrowUp" || event.key === "ArrowDown") {
                event.preventDefault();
            }
        },
        format_one_decimal(e) {
            if (Math.round(e * 10) / 10 != e) {
                let text = String(e);
                let filter = text.substring(0, text.length - 1);
                return filter;
            } else {
                if (Math.floor(e) === e) {
                    return e;
                } else {
                    if (e.toString().indexOf(".") > 0) {
                        let count = e.toString().split(".")[1].length || 0;
                        return count > 1 ? parseFloat(e).toFixed(1) : e;
                    } else {
                        return e;
                    }
                }
                return e;
            }
        },
        //validate tooltips GC
        tooltipMessage(attr) {
            return GC_PIAS(attr).message;
        },
        validateTooltipState(name) {
            // const { $error } = this.$v[name];
            // return $error;
            return false;
        },
        validateState(item) {
            const { $dirty, $error } = item;
            return $dirty ? !$error : null;
        },
        alertObserver() {
            this.$swal({
                title: "Observer",
                text: "Pilih observer terlebih dahulu",
                icon: "warning",
                confirmButtonText: "Ok",
                allowOutsideClick: true,
                allowEscapeKey: true,
                customClass: {
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
                })
        },
        V_Form() {
            if (this.t_observer === "" || this.t_observer === null) {
                this.alertObserver()
                return
            }

            this.$v.$touch();

            // stop here if form is invalid
            if (this.$v.$invalid) {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Data tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
                return;
            } else {
                if (this.t_data_status == "insert") {
                    this.submitFormLamaPenyinaran();
                } else if (this.t_data_status == "update") {
                    this.updateData();
                }
            }
        },
        popupData() {
            this.ss_6_7 = this.t_data.ss_6_7;
            this.ss_7_8 = this.t_data.ss_7_8;
            this.ss_8_9 = this.t_data.ss_8_9;
            this.ss_9_10 = this.t_data.ss_9_10;
            this.ss_10_11 = this.t_data.ss_10_11;
            this.ss_11_12 = this.t_data.ss_11_12;
            this.ss_12_13 = this.t_data.ss_12_13;
            this.ss_13_14 = this.t_data.ss_13_14;
            this.ss_14_15 = this.t_data.ss_14_15;
            this.ss_15_16 = this.t_data.ss_15_16;
            this.ss_16_17 = this.t_data.ss_16_17;
            this.ss_17_18 = this.t_data.ss_17_18;
            this.ss_total_8 = this.t_data.ss_total_8;
            this.ss_total_12 = this.t_data.ss_total_12;
        },
        updateData() {
            
            this.$emit("set-loading", true);
            const modification_date = this.t_date;
            const post_data = {
                "@type": "LamaPenyinaran",
                ss_6_7: this.ss_6_7,
                ss_7_8: this.ss_7_8,
                ss_8_9: this.ss_8_9,
                ss_9_10: this.ss_9_10,
                ss_10_11: this.ss_10_11,
                ss_11_12: this.ss_11_12,
                ss_12_13: this.ss_12_13,
                ss_13_14: this.ss_13_14,
                ss_14_15: this.ss_14_15,
                ss_15_16: this.ss_15_16,
                ss_16_17: this.ss_16_17,
                ss_17_18: this.ss_17_18,
                ss_total_8: parseFloat(this.ss_total_8),
                ss_total_12: parseFloat(this.ss_total_12),
                modification_date: modification_date,
                observer_id: this.t_observer,
                observer_name: this.t_observer_name,
            };

            const update_url = Helper.getBaseUrl() + this.t_path_update;

            axios
                .patch(update_url, post_data, Helper.getConfig())

                .then((response) => {
                    this.$emit("set-loading", false);
                    this.$swal({
                        title: "Update data sukses!",
                        text: "Data Telah berhasil di Update",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    
                    this.$emit("reset-filter", null);
                    this.resetForm();
                })
                .catch((error) => {
                    this.$emit("set-loading", false);
                    error_handler(this, error, "Lama Penyinaran", "Input Data Error");
                    console.log(error);
                });
        },
        submitFormLamaPenyinaran() {
            
            this.$emit("set-loading", true);
            const lamapenyinaran_id = this.t_station_id + "_lamapenyinaran_" + this.t_date;
            const data_timestamp = this.t_date;
            const post_data = {
                "@type": "LamaPenyinaran",
                id: lamapenyinaran_id,
                ss_6_7: this.ss_6_7,
                ss_7_8: this.ss_7_8,
                ss_8_9: this.ss_8_9,
                ss_9_10: this.ss_9_10,
                ss_10_11: this.ss_10_11,
                ss_11_12: this.ss_11_12,
                ss_12_13: this.ss_12_13,
                ss_13_14: this.ss_13_14,
                ss_14_15: this.ss_14_15,
                ss_15_16: this.ss_15_16,
                ss_16_17: this.ss_16_17,
                ss_17_18: this.ss_17_18,
                ss_total_8: parseFloat(this.ss_total_8),
                ss_total_12: parseFloat(this.ss_total_12),
                data_timestamp: data_timestamp,
                observer_id: this.t_observer,
                observer_name: this.t_observer_name,
            };

            const post_url = Helper.getBaseUrl() + this.t_path;

            axios
                .post(post_url, post_data, Helper.getConfig())

                .then((response) => {
                    this.$emit("set-loading", false);
                    this.$swal({
                        title: "Input data sukses!",
                        text: "Data Telah berhasil di simpan",
                        icon: "success",
                        customClass: {
                            confirmButton: "btn btn-primary",
                        },
                        buttonsStyling: false,
                    });
                    this.$emit("reset-filter", null);
                    this.resetForm();
                })
                .catch((error) => {
                    this.$emit("set-loading", false);
                    error_handler(this, error, "Lama Penyinaran", "Input Data Error");
                    console.log(error);
                });
        },
        resetForm() {
            this.ss_6_7 = "";
            this.ss_7_8 = "";
            this.ss_8_9 = "";
            this.ss_9_10 = "";
            this.ss_10_11 = "";
            this.ss_11_12 = "";
            this.ss_12_13 = "";
            this.ss_13_14 = "";
            this.ss_14_15 = "";
            this.ss_15_16 = "";
            this.ss_16_17 = "";
            this.ss_17_18 = "";
            this.$v.$reset();
        },
    },
    computed: {
        ss_total_8: function () {
            // if(this.ss_8_9 == 9999 && this.ss_9_10 == 9999 && this.ss_10_11 == 9999 &&
            //   this.ss_11_12 == 9999 && this.ss_12_13 == 9999 && this.ss_13_14 == 9999 &&
            //   this.ss_14_15 == 9999 && this.ss_15_16 == 9999
            // )
            if (this.ss_8_9 == 9999 || this.ss_9_10 == 9999 || this.ss_10_11 == 9999 || this.ss_11_12 == 9999 || this.ss_12_13 == 9999 || this.ss_13_14 == 9999 || this.ss_14_15 == 9999 || this.ss_15_16 == 9999) {
                return parseInt(9999);
            } else if (this.ss_8_9 == 8888 && this.ss_9_10 == 8888 && this.ss_10_11 == 8888 && this.ss_11_12 == 8888 && this.ss_12_13 == 8888 && this.ss_13_14 == 8888 && this.ss_14_15 == 8888 && this.ss_15_16 == 8888) {
                return parseInt(8888);
            } else {
                return parseFloat(
                    ((this.ss_8_9 == 8888 ? 0 : this.ss_8_9 == 9999 ? 0 : this.ss_8_9) || 0) +
                        ((this.ss_9_10 == 8888 ? 0 : this.ss_9_10 == 9999 ? 0 : this.ss_9_10) || 0) +
                        ((this.ss_10_11 == 8888 ? 0 : this.ss_10_11 == 9999 ? 0 : this.ss_10_11) || 0) +
                        ((this.ss_11_12 == 8888 ? 0 : this.ss_11_12 == 9999 ? 0 : this.ss_11_12) || 0) +
                        ((this.ss_12_13 == 8888 ? 0 : this.ss_12_13 == 9999 ? 0 : this.ss_12_13) || 0) +
                        ((this.ss_13_14 == 8888 ? 0 : this.ss_13_14 == 9999 ? 0 : this.ss_13_14) || 0) +
                        ((this.ss_14_15 == 8888 ? 0 : this.ss_14_15 == 9999 ? 0 : this.ss_14_15) || 0) +
                        ((this.ss_15_16 == 8888 ? 0 : this.ss_15_16 == 9999 ? 0 : this.ss_15_16) || 0)
                ).toFixed(1);
            }
        },
        percen_8h: function () {
            const percen = ((parseFloat(this.ss_total_8) / 8) * 100).toFixed(1);
            var value = 0;
            if (percen == 0 || percen == 100) {
                value = parseInt(percen);
            } else {
                value = percen;
            }
            if (this.ss_total_8 == 9999) {
                return parseInt(9999);
            } else if (this.ss_total_8 == 8888) {
                return parseInt(8888);
            } else {
                if (value.toString() != "NaN") {
                    return value.toString() + "%";
                }
            }
        },
        ss_total_12: function () {
            // if (
            //     this.ss_8_9 == 9999 &&
            //     this.ss_9_10 == 9999 &&
            //     this.ss_10_11 == 9999 &&
            //     this.ss_11_12 == 9999 &&
            //     this.ss_12_13 == 9999 &&
            //     this.ss_13_14 == 9999 &&
            //     this.ss_14_15 == 9999 &&
            //     this.ss_15_16 == 9999 &&
            //     this.ss_6_7 == 9999 &&
            //     this.ss_7_8 == 9999 &&
            //     this.ss_16_17 == 9999 &&
            //     this.ss_17_18 == 9999
            // )
            if (
                this.ss_8_9 == 9999 ||
                this.ss_9_10 == 9999 ||
                this.ss_10_11 == 9999 ||
                this.ss_11_12 == 9999 ||
                this.ss_12_13 == 9999 ||
                this.ss_13_14 == 9999 ||
                this.ss_14_15 == 9999 ||
                this.ss_15_16 == 9999 ||
                this.ss_6_7 == 9999 ||
                this.ss_7_8 == 9999 ||
                this.ss_16_17 == 9999 ||
                this.ss_17_18 == 9999
            ) {
                return parseInt(9999);
            } else if (
                this.ss_8_9 == 8888 &&
                this.ss_9_10 == 8888 &&
                this.ss_10_11 == 8888 &&
                this.ss_11_12 == 8888 &&
                this.ss_12_13 == 8888 &&
                this.ss_13_14 == 8888 &&
                this.ss_14_15 == 8888 &&
                this.ss_15_16 == 8888 &&
                this.ss_6_7 == 8888 &&
                this.ss_7_8 == 8888 &&
                this.ss_16_17 == 8888 &&
                this.ss_17_18 == 8888
            ) {
                return parseInt(8888);
            } else {
                return parseFloat(
                    ((this.ss_6_7 == 8888 ? 0 : this.ss_6_7 == 9999 ? 0 : this.ss_6_7) || 0) +
                        ((this.ss_7_8 == 8888 ? 0 : this.ss_7_8 == 9999 ? 0 : this.ss_7_8) || 0) +
                        ((this.ss_8_9 == 8888 ? 0 : this.ss_8_9 == 9999 ? 0 : this.ss_8_9) || 0) +
                        ((this.ss_9_10 == 8888 ? 0 : this.ss_9_10 == 9999 ? 0 : this.ss_9_10) || 0) +
                        ((this.ss_10_11 == 8888 ? 0 : this.ss_10_11 == 9999 ? 0 : this.ss_10_11) || 0) +
                        ((this.ss_11_12 == 8888 ? 0 : this.ss_11_12 == 9999 ? 0 : this.ss_11_12) || 0) +
                        ((this.ss_12_13 == 8888 ? 0 : this.ss_12_13 == 9999 ? 0 : this.ss_12_13) || 0) +
                        ((this.ss_13_14 == 8888 ? 0 : this.ss_13_14 == 9999 ? 0 : this.ss_13_14) || 0) +
                        ((this.ss_14_15 == 8888 ? 0 : this.ss_14_15 == 9999 ? 0 : this.ss_14_15) || 0) +
                        ((this.ss_15_16 == 8888 ? 0 : this.ss_15_16 == 9999 ? 0 : this.ss_15_16) || 0) +
                        ((this.ss_16_17 == 8888 ? 0 : this.ss_16_17 == 9999 ? 0 : this.ss_16_17) || 0) +
                        ((this.ss_17_18 == 8888 ? 0 : this.ss_17_18 == 9999 ? 0 : this.ss_17_18) || 0)
                ).toFixed(1);
            }
        },
        percen_12h: function () {
            const percen = ((parseFloat(this.ss_total_12) / 12) * 100).toFixed(1);
            var value = 0;
            if (percen == 0 || percen == 100) {
                value = parseInt(percen);
            } else {
                value = percen;
            }
            if (this.ss_total_12 == 9999) {
                return parseInt(9999);
            } else if (this.ss_total_12 == 8888) {
                return parseInt(8888);
            } else {
                if (value.toString() != "NaN") {
                    return value.toString() + "%";
                }
            }
        },
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
};
</script>
<style scoped>
.label-entry1 {
    text-align: center;
    color: #3b4253;
    width: 100%;
    font-weight: 500;
    background-color: #f3f1f1;
    /* font-weight: bold !important; */
    border: 2px solid #6e6b7b;
    border-radius: 3px;
    padding: 4px;
}
.pad1 {
    padding-left: 4px;
    padding-right: 4px;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
</style>
<style>
/* .form-control.is-invalid {
  background-size: 12px;
} */
</style>
