export function V_LamaPenyinaran(value) {
  return (value >= 0 && value <= 1) || value == 9999;
}

export function V_Aktinograf(value) {
  return (value >= 0 && value <= 3000) || value == 9999;
}
export function V_aktinograf_19_24(value) {
  return value == 0 || value == 9999;
}
export function V_TermohigrografSuhu(value) {
  return (value >= 5 && value <= 40) || value == 9999;
}
export function CEK_REQUIRED(value) {
  console.log("awas value : ", value);
  return value != "" && value != null && value != "";
}

export function V_TermohigrografKelebaban(value) {
  return (value >= 0 && value <= 100) || value == 9999;
}

export function V_Hujan(value) {
  return (value >= 0 && value <= 500) || value == 9999 || value == 8888;
}

export function V_Barograf(value) {
  return (value >= 700 && value <= 1100) || value == 9999;
}
export function V_IntensitasHujan(value) {
  return (value >= 0 && value <= 200) || value == 9999;
}
export function V_KEDALAMAN_TANAH(value) {
  return (value >= 0 && value <= 100) || value == 9999;
}
// RANGE CHECK FDIH
const RC = {
  hellman_5m: { min: 0, max: 100 },
  hellman_10m: { min: 0, max: 100 },
  hellman_15m: { min: 0, max: 100 },
  hellman_30m: { min: 0, max: 100 },
  hellman_45m: { min: 0, max: 100 },
  hellman_60m: { min: 0, max: 100 },
  hellman_120m: { min: 0, max: 100 },
  hellman_3h: { min: 0, max: 100 },
  hellman_6h: { min: 0, max: 100 },
  hellman_12h: { min: 0, max: 100 },
  hellman_07_08: { min: 0, max: 100 },
  hellman_08_09: { min: 0, max: 100 },
  hellman_09_10: { min: 0, max: 100 },
  hellman_10_11: { min: 0, max: 100 },
  hellman_11_12: { min: 0, max: 100 },
  hellman_12_13: { min: 0, max: 100 },
  hellman_13_14: { min: 0, max: 100 },
  hellman_14_15: { min: 0, max: 100 },
  hellman_15_16: { min: 0, max: 100 },
  hellman_16_17: { min: 0, max: 100 },
  hellman_17_18: { min: 0, max: 100 },
  hellman_18_19: { min: 0, max: 100 },
  hellman_19_20: { min: 0, max: 100 },
  hellman_20_21: { min: 0, max: 100 },
  hellman_21_22: { min: 0, max: 100 },
  hellman_22_23: { min: 0, max: 100 },
  hellman_23_24: { min: 0, max: 100 },
  hellman_24_01: { min: 0, max: 100 },
  hellman_01_02: { min: 0, max: 100 },
  hellman_02_03: { min: 0, max: 100 },
  hellman_03_04: { min: 0, max: 100 },
  hellman_04_05: { min: 0, max: 100 },
  hellman_05_06: { min: 0, max: 100 },
  hellman_06_07: { min: 0, max: 100 },
  hellman_05_06: { min: 0, max: 100 },

  col_1_1: { min: 0, max: 350 },
  col_1_2: { min: 0, max: 350 },
  col_1_3: { min: 0, max: 200 },
  col_1_4: { min: 0, max: 200 },
  col_1_5: { min: 0, max: 200 },
  col_1_6: { min: 0, max: 200 },
  col_1_7: { min: 0, max: 200 },
  col_1_8: { min: 0, max: 200 },
  col_1_9: { min: 0, max: 200 },
  col_1_10: { min: 0, max: 200 },
  col_2_11: { min: 0, max: 200 },
  col_2_12: { min: 0, max: 200 },
  col_2_13: { min: 0, max: 200 },
  col_2_14: { min: 0, max: 200 },
  col_2_15: { min: 0, max: 200 },
  col_2_16: { min: 0, max: 200 },
  col_2_17: { min: 0, max: 200 },
  col_2_18: { min: 0, max: 200 },
  col_2_19: { min: 0, max: 200 },
  col_2_20: { min: 0, max: 200 },
  col_3_21: { min: 0, max: 200 },
  col_3_22: { min: 0, max: 200 },
  col_3_23: { min: 0, max: 200 },
  col_3_24: { min: 0, max: 200 },
  col_3_25: { min: 0, max: 200 },
  col_3_26: { min: 0, max: 200 },
  col_3_27: { min: 0, max: 200 },
  col_3_28: { min: 0, max: 200 },
  col_3_29: { min: 0, max: 200 },
  col_3_30: { min: 0, max: 200 },
  col_3_31: { min: 0, max: 200 },
};

export function RC_PIAS(value, key) {
  let status = false;
  let message = "";
  let min = RC[key].min;
  let max = RC[key].max;
  if ((value >= min && value <= max) || value == 9999 || value == 8888) {
    status = true;
    message = "";
  } else {
    status = false;
    message = "Range Check - Batas Bawah " + min + ", Batas Atas " + max;
  }

  return { status: status, message: message };
}

const GC = {
  //lama penyinaran
  ss_6_7: { min: 0, max: 1, specialValue: [9999] },
  ss_7_8: { min: 0, max: 1, specialValue: [9999] },
  ss_8_9: { min: 0, max: 1, specialValue: [9999] },
  ss_9_10: { min: 0, max: 1, specialValue: [9999] },
  ss_10_11: { min: 0, max: 1, specialValue: [9999] },
  ss_11_12: { min: 0, max: 1, specialValue: [9999] },
  ss_12_13: { min: 0, max: 1, specialValue: [9999] },
  ss_13_14: { min: 0, max: 1, specialValue: [9999] },
  ss_14_15: { min: 0, max: 1, specialValue: [9999] },
  ss_15_16: { min: 0, max: 1, specialValue: [9999] },
  ss_16_17: { min: 0, max: 1, specialValue: [9999] },
  ss_17_18: { min: 0, max: 1, specialValue: [9999] },
  //GC aktinograf

  //GC Termohigrograf
  suhu_1: { min: 5, max: 40, specialValue: [9999] },
  suhu_2: { min: 5, max: 40, specialValue: [9999] },
  suhu_3: { min: 5, max: 40, specialValue: [9999] },
  suhu_4: { min: 5, max: 40, specialValue: [9999] },
  suhu_5: { min: 5, max: 40, specialValue: [9999] },
  suhu_6: { min: 5, max: 40, specialValue: [9999] },
  suhu_7: { min: 5, max: 40, specialValue: [9999] },
  suhu_8: { min: 5, max: 40, specialValue: [9999] },
  suhu_9: { min: 5, max: 40, specialValue: [9999] },
  suhu_10: { min: 5, max: 40, specialValue: [9999] },
  suhu_11: { min: 5, max: 40, specialValue: [9999] },
  suhu_12: { min: 5, max: 40, specialValue: [9999] },
  suhu_13: { min: 5, max: 40, specialValue: [9999] },
  suhu_14: { min: 5, max: 40, specialValue: [9999] },
  suhu_15: { min: 5, max: 40, specialValue: [9999] },
  suhu_16: { min: 5, max: 40, specialValue: [9999] },
  suhu_17: { min: 5, max: 40, specialValue: [9999] },
  suhu_18: { min: 5, max: 40, specialValue: [9999] },
  suhu_19: { min: 5, max: 40, specialValue: [9999] },
  suhu_20: { min: 5, max: 40, specialValue: [9999] },
  suhu_21: { min: 5, max: 40, specialValue: [9999] },
  suhu_22: { min: 5, max: 40, specialValue: [9999] },
  suhu_23: { min: 5, max: 40, specialValue: [9999] },
  suhu_24: { min: 5, max: 40, specialValue: [9999] },

  kelembaban_1: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_2: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_3: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_4: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_5: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_6: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_7: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_8: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_9: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_10: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_11: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_12: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_13: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_14: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_15: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_16: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_17: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_18: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_19: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_20: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_21: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_22: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_23: { min: 0, max: 100, specialValue: [9999] },
  kelembaban_24: { min: 0, max: 100, specialValue: [9999] },
  //form hujan
  col_1_1: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_2: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_3: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_4: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_5: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_6: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_7: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_8: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_9: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_1_10: { min: 0, max: 500, specialValue: [9999, 8888] },

  col_2_11: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_12: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_13: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_14: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_15: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_16: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_17: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_18: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_19: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_2_20: { min: 0, max: 500, specialValue: [9999, 8888] },

  col_3_21: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_22: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_23: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_24: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_25: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_26: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_27: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_28: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_29: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_30: { min: 0, max: 500, specialValue: [9999, 8888] },
  col_3_31: { min: 0, max: 500, specialValue: [9999, 8888] },
  //GC Barograph
  tekanan_po_baro_07: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_08: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_09: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_10: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_11: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_12: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_13: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_14: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_15: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_16: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_17: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_18: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_19: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_20: { min: 700, max: 1100, specialValue: [9999] },

  tekanan_po_baro_21: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_22: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_23: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_24: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_01: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_02: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_03: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_04: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_05: { min: 700, max: 1100, specialValue: [9999] },
  tekanan_po_baro_06: { min: 700, max: 1100, specialValue: [9999] },

  //hujan hellman
  hellman_5m: { min: 0, max: 200, specialValue: [9999] },
  hellman_10m: { min: 0, max: 200, specialValue: [9999] },
  hellman_15m: { min: 0, max: 200, specialValue: [9999] },
  hellman_30m: { min: 0, max: 200, specialValue: [9999] },
  hellman_45m: { min: 0, max: 200, specialValue: [9999] },
  hellman_60m: { min: 0, max: 200, specialValue: [9999] },
  hellman_120m: { min: 0, max: 200, specialValue: [9999] },
  hellman_3h: { min: 0, max: 3*200, specialValue: [9999] },
  hellman_6h: { min: 0, max: 6*200, specialValue: [9999] },
  hellman_12h: { min: 0, max: 12*200, specialValue: [9999] },
  hellman_07_08: { min: 0, max: 200, specialValue: [9999] },
  hellman_08_09: { min: 0, max: 200, specialValue: [9999] },
  hellman_09_10: { min: 0, max: 200, specialValue: [9999] },
  hellman_10_11: { min: 0, max: 200, specialValue: [9999] },
  hellman_11_12: { min: 0, max: 200, specialValue: [9999] },
  hellman_12_13: { min: 0, max: 200, specialValue: [9999] },
  hellman_13_14: { min: 0, max: 200, specialValue: [9999] },
  hellman_14_15: { min: 0, max: 200, specialValue: [9999] },
  hellman_15_16: { min: 0, max: 200, specialValue: [9999] },
  hellman_16_17: { min: 0, max: 200, specialValue: [9999] },
  hellman_17_18: { min: 0, max: 200, specialValue: [9999] },
  hellman_18_19: { min: 0, max: 200, specialValue: [9999] },
  hellman_19_20: { min: 0, max: 200, specialValue: [9999] },
  hellman_20_21: { min: 0, max: 200, specialValue: [9999] },
  hellman_21_22: { min: 0, max: 200, specialValue: [9999] },
  hellman_22_23: { min: 0, max: 200, specialValue: [9999] },
  hellman_23_24: { min: 0, max: 200, specialValue: [9999] },
  hellman_24_01: { min: 0, max: 200, specialValue: [9999] },
  hellman_01_02: { min: 0, max: 200, specialValue: [9999] },
  hellman_02_03: { min: 0, max: 200, specialValue: [9999] },
  hellman_03_04: { min: 0, max: 200, specialValue: [9999] },
  hellman_04_05: { min: 0, max: 200, specialValue: [9999] },
  hellman_05_06: { min: 0, max: 200, specialValue: [9999] },
  hellman_06_07: { min: 0, max: 200, specialValue: [9999] },

  input_19_20: { min: 0, max: "-", specialValue: [9999] },
  input_20_21: { min: 0, max: "-", specialValue: [9999] },
  input_21_22: { min: 0, max: "-", specialValue: [9999] },
  input_22_23: { min: 0, max: "-", specialValue: [9999] },
  input_23_24: { min: 0, max: "-", specialValue: [9999] },
};

export function GC_PIAS(key) {
  let status = false;
  let message = "";
  let min = GC[key].min;
  let max = GC[key].max;
  let spv = GC[key].specialValue;

  status = false;
  message = "Gross Check - Batas Bawah " + min + ", Batas Atas " + max + ", Special Value " + spv;
  return { status: status, message: message };
}

export function GC_PIAS_AKTINOGRAPH(key) {
  let status = false;
  let message = "";
  let min = GC[key].min;
  let max = GC[key].max;
  let spv = GC[key].specialValue;

  status = false;
  message = "Hanya dapat di input nilai " + min + ", dan " + spv;
  return { status: status, message: message };
}
